.welcome-container-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  background-image: url('../../assets/portrait-coding-moi-large.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  
}

.welcome-container-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
}

.welcome-container-wrapper .container {
  position: relative;
  box-sizing: border-box;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 50px 0px;
  max-width: 1280px;
}
  
  .welcome-container-wrapper .container .title {
    color: #FFFFFF;
    font-size: 18px;
    font-weight: bold;
  }
  
  .welcome-container-wrapper .container .quote {
    color: #FFFFFF;
    font-size: 78px;
    font-weight: bold;
    padding: 0px 250px 0px 70px;
    margin-top: 150px;
  }
  
  .welcome-container-wrapper .container .contact-button {
    background-color: #FBFF12;
    font-size: 22px;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    font-size: 18px;
    font-weight: bold;
    font-family: 'DM Sans', sans-serif;
    box-shadow: 0px 0px 0px #adafff;
    transition: all 0.3s ease;
    cursor: pointer;
    position: relative;
  }
  
  .welcome-container-wrapper .container .contact-button:hover {
    transform: translate(-4px, 4px);
    box-shadow: 4px -4px 0px #adafff;
  }
  
  /* Adding Media Queries for Responsiveness */
  
  @media screen and (max-width: 1200px) {
    .welcome-container-wrapper .container .quote {
      font-size: 50px;
      padding: 0px 150px 0px 50px;
    }
  }
  
  @media screen and (max-width: 800px) {
    .welcome-container-wrapper .container .quote {
      font-size: 36px;
      padding: 0px 80px 0px 30px;
    }
  
    .welcome-container-wrapper .container .contact-button {
      font-size: 16px;
    }
  }
  
  @media screen and (max-width: 600px) {
    .welcome-container-wrapper .container .quote {
      text-align: center;
      font-size: 45px;
      padding: 0px 30px;
      margin-top: 30px;
    }
  
    .welcome-container-wrapper .container .title {
      font-size: 20px;
      text-align: center;
    }
  
    .welcome-container-wrapper .container .contact-button {
      font-size: 18px;
      padding: 16px 32px;
      color: black
    }
  }
  