.services-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #fff;
    padding: 75px 0px 75px 0px;
}

.services-container {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch; /* Make the columns have equal height */
    justify-content: space-around;
    width: 100%;
    max-width: 1280px;
}

.services-image {
    width: 300px; /* Initial width for desktop */
    height: auto;
    object-fit: cover; 

}

.services-title {
    font-size: 62px;
    margin: 0;
    max-width: 570px;
}

.services-description {
    font-size: 18px;
    max-width: 400px;
}

.services-box {
    width: 100%;   
}

.services-box h1 {
    max-width: 300px;
    font-size: 28px;
}

.services-box p {
    max-width: 300px;
    font-size: 18px;
}

.services-box:not(:last-child):after {
    content: ""; /* Create a pseudo-element */
    display: block;
    width: 120%; /* Make this full width of the box */
    height: 2px; /* Set its height */
    background: black; /* Set its color */
    margin: 10px 0; /* Some margin to separate it from the content */
}

.services-inner-container {
    width: 100%; /* Full width of the column */
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Aligns content to the left */
    justify-content: space-between;
    min-height: 100%;
}

/* Adding Media Queries for Responsiveness */
@media screen and (max-width: 900px) {

    .services-box:not(:last-child):after {
        width: 95%; /* Make this full width of the box */
    }

    .services-column {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
    }

    .services-title {
        font-size: 36px; /* Increase the font size */
    }

    .services-description,
    .services-box p {
        font-size: 16px;
    }

    .services-image {
        width: 50%;
        margin: auto;
    }

    .services-box h1 { /* Decrease the font size of the services title */
        font-size: 24px;
    }
}

@media screen and (max-width: 600px) {



    .services-wrapper {
        padding: 35px 0px 35px 0px;
    }

    .services-description {
        padding: 10px;
    }

    .services-box {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .services-title {
        font-size: 28px; /* Adjust the main title font size */
        margin: auto;
        text-align: center;
        margin-bottom: 20px;
    }



    .services-image {
        margin-bottom: 30px;
    }

   
    .services-box p {

        text-align: center;
        font-size: 14px;
    }

    .services-box h1 { /* Adjust the services title font size */
        text-align: center;
        font-size: 20px;
    }
}
