.contact-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #2D5FCF;
  color: white;
  padding: 70px 0px 90px 0px;
}

.contact-header, .contact-header-success {
  margin-bottom: 30px;
  font-size: 78px;
  margin-top: 0px;
  text-align: center;
}

.contact-form {
  display: flex;
  flex-direction: column;
  width: 90%; /* Adjust for padding on smaller screens */
  max-width: 600px;
}

.contact-form-label {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
}

.contact-form-input, .contact-form-textarea {
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 4px;
}

.contact-form-textarea {
  min-height: 100px;
}

.contact-form-button {
  background-color: #FBFF12;
  font-size: 22px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease;
  position: relative;
  color: rgb(0, 0, 0)
}

.contact-form-button:hover {
  transform: translate(-4px, 4px);
  box-shadow: 4px -4px 0px #adafff;
}

/* Adding Media Queries for Responsiveness */
@media screen and (max-width: 900px) {
  .contact-header, .contact-header-success {
      font-size: 40px;
  }

  .contact-form-button {
      font-size: 20px;
  }
}

@media screen and (max-width: 600px) {
  .contact-header, .contact-header-success {
      font-size: 32px;
  }

  .contact-form-button {
      font-size: 18px;
  }
}
