.about-me-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #2D5FCF;
}

.about-me-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 90%;
    max-width: 1280px;
    padding: 100px 0px;
    gap: 2%; 
}

.about-me-description {
    width: 45%;
    color: #fff;
}

.about-me-description .about-me-title { 
    font-size: 44px;
    margin-top: 0;
    margin-bottom: 50px;
}

.about-me-description .about-me-content {
    font-size: 20px;
}

.about-me-image-container {
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 80px;    
    border-bottom-right-radius: 80px;    
    overflow: hidden; 
}

.about-me-image-container img {
    width: 100%; 
    height: auto;
    object-fit: cover; 
}

.social-links {
    display: flex;
    gap: 20px;  /* Adjust this as per your preference */
    margin-top: 20px;
}

.social-link {
    color: #fff; /* Adjust this as per your preference */
    transition: color 0.3s;

    &:hover {
        color: #ddd;  /* Adjust this for hover effect */
    }
}

.social-icon {
    font-size: 24px;  /* Adjust this as per your preference */
}


/* Adding Media Queries for Responsiveness */
@media screen and (max-width: 900px) {
    .about-me-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .about-me-description,
    .about-me-image-container {
        width: 100%;
    }

    .about-me-description .about-me-title { 
        font-size: 32px;
        margin-bottom: 30px;
    }

    .about-me-description .about-me-content {
        font-size: 16px;
    }
}

@media screen and (max-width: 600px) {

    .about-me-container {
        padding: 40px 0px;
    }

    .social-links {
        justify-content: center
    }

    .about-me-description .about-me-title { 
        font-size: 24px;
    }

    .about-me-description .about-me-content {
        font-size: 14px;
    }
    .about-me-image-container {   
        margin-top: 20px;
        border-radius: 10px;
    }

    .about-me-image-container img {
        height: 300px;
    }
}
